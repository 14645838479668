<template>
  <div
    class="py-16 bg-grey-light min-h-full"
  >
    <v-inner-header
      :hide-close="true"
    />
    <vue-headful
      title="Forget password | Octoa"
    />
    <div class="max-w-xs mt-10 text-center mx-auto">
      <router-link
        class="mb-3 block"
        to="/"
      />
      <h1 class="mt-16 mb-8 text-2xl lg:text-3xl">
        Forget Password
      </h1>
    </div>
    <div class="px-4">
      <form
        class="max-w-sm mx-auto bg-white rounded mt-8 px-8 pt-6 pb-8 mb-4"
        @submit.prevent="send"
      >
        <div class="mb-4">
          <label
            class="block text-grey-darker text-sm mb-2"
            for="username"
          >
            Email address
          </label>
          <input
            id="email"
            ref="email"
            v-model="form.email"
            class="w-full"
            type="text"
            placeholder="Email address"
          >
        </div>
        <button
          class="green-btn w-full rounded"
          type="submit"
        >
          Request password reset
        </button>
      </form>
    </div>
    <div class="mt-4 max-w-md text-center mx-auto">
      <router-link
        to="/login"
        class="inline-block underline align-baseline text-sm text-grey-darker hover:text-blue-darker"
      >
        Back to login
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .title {
    margin: 70px 0;
    padding: 0;
    display: block;
    text-align: center;
  }
</style>

<script>
import { logException } from '@/helpers'

export default {
  name: 'ForgetPassword',
  data() {
    return {
      form: {
        email: '',
      },
      loading: false
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    async send() {
      this.loading = true
      this.$toasted.clear()

      try {
        const { data } = await this.$api.get('user').forgetPassword(this.form)

        this.form.email = ''
        this.$toasted.global.general_success({
          message : 'An email has been sent to reset your password.'
        })

        //setTimeout(() => this.$router.replace({ name: 'Login' }), 3000)

      }catch(e){
        this.$toasted.global.general_error({
         message : e.response.data.errors.email
        })
      }

      this.loading = false
    }
  }
}
</script>
